.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 10vh;
    height: 20vh;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #2c5FFE;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
  
  @keyframes ldio-2j6q63zyi35 {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-2j6q63zyi35 div {
    left: 47.5px;
    top: 6px;
    position: absolute;
    animation: ldio-2j6q63zyi35 linear 0.9433962264150942s infinite;
    background: #2c5ffe;
    width: 5px;
    height: 20px;
    border-radius: 2.4px / 2.4px;
    transform-origin: 2.5px 44px;
  }.ldio-2j6q63zyi35 div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.890985324947589s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(2) {
    transform: rotate(20deg);
    animation-delay: -0.8385744234800838s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(3) {
    transform: rotate(40deg);
    animation-delay: -0.7861635220125786s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(4) {
    transform: rotate(60deg);
    animation-delay: -0.7337526205450733s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(5) {
    transform: rotate(80deg);
    animation-delay: -0.681341719077568s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(6) {
    transform: rotate(100deg);
    animation-delay: -0.6289308176100629s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(7) {
    transform: rotate(120deg);
    animation-delay: -0.5765199161425576s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(8) {
    transform: rotate(140deg);
    animation-delay: -0.5241090146750523s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(9) {
    transform: rotate(160deg);
    animation-delay: -0.4716981132075471s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(10) {
    transform: rotate(180deg);
    animation-delay: -0.4192872117400419s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(11) {
    transform: rotate(200deg);
    animation-delay: -0.36687631027253664s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(12) {
    transform: rotate(220deg);
    animation-delay: -0.31446540880503143s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(13) {
    transform: rotate(240deg);
    animation-delay: -0.26205450733752617s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(14) {
    transform: rotate(260deg);
    animation-delay: -0.20964360587002095s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(15) {
    transform: rotate(280deg);
    animation-delay: -0.15723270440251572s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(16) {
    transform: rotate(300deg);
    animation-delay: -0.10482180293501048s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(17) {
    transform: rotate(320deg);
    animation-delay: -0.05241090146750524s;
    background: #2c5ffe;
  }.ldio-2j6q63zyi35 div:nth-child(18) {
    transform: rotate(340deg);
    animation-delay: 0s;
    background: #2c5ffe;
  }
  .loadingio-spinner-spinner-z515pskmu3b {
    width: 60px;
    height: 60px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-2j6q63zyi35 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.6);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-2j6q63zyi35 div { box-sizing: content-box; }
  
  
  
  /* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
  
  .lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #2c5FFE;
    border-color: #2c5FFE transparent #2c5FFE transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    100% {
      transform: rotate(1800deg);
    }
  }
  
  
  /* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
  
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .lds-ripple div {
    position: absolute;
    border: 4px solid #2c5FFE;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
  
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
  
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
  
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  
  
  /* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
  
  .lds-roller {
    display: inline-block;
    position: relative;
    width: auto;
    height: 20vh;
  }
  
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: #2c5FFE;
    margin: -4px 0 0 -4px;
  }
  
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }