
.input {
    width: 30px;
    border: none;
    margin: 0 10px;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    border-bottom: 3px solid rgba(0, 0, 0, 0.5);
}
 
.input:focus {
    outline: none;
    border-bottom: 3px solid #2c5FFE;
}
