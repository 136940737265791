html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Noto Sans';
  background-color: #FAFAFA !important;
}

code {
  font-family: 'Noto Sans';
  background-color: '#FAFAFA !important';
}

.hover-underline-class:hover {
  text-decoration: underline;
}
.suggestion-list {
  /* Enable hardware acceleration */
  transform: translateZ(0);
  will-change: transform;
  
  /* Smooth scrolling */
  scroll-behavior: smooth;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

#pdf-controls {
  display: none;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  /* height: 100%;
  width: 100%; */
  position: absolute;
}

.border-left-success {
  border-left: 3px solid #28a745;
  border-radius: 4px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiOutlinedInput-root legend {
  width: 0px !important;
}

.css-5z4w2d-MuiButtonBase-root-MuiButton-root {
  font-family: 'Noto Sans !important';
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none !important;
}

/* Up arrow button */
.display_scroll_bar::-webkit-scrollbar-button:start:decrement {
  background-color: #f1f1f1;
  height: 16px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,25 75,65 25,65'/></svg>");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.display_scroll_bar::-webkit-scrollbar-button:hover {
  background-color: #e1e1e1;
}

/* Down arrow button */
.display_scroll_bar::-webkit-scrollbar-button:end:increment {
  background-color: #f1f1f1;
  height: 16px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,65 75,25 25,25'/></svg>");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.display_scroll_bar::-webkit-scrollbar-button:active {
  background-color: #d1d1d1;
}

.display_scroll_bar::-webkit-scrollbar {
  width: 17px !important;
  display: block !important;
  cursor: pointer !important;
}

.display_scroll_bar::-webkit-scrollbar-track {
  cursor: pointer !important;
  /* border-radius: 100vh !important; */
  background: #f1f1f1 !important;
}

.display_scroll_bar::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  background: #949394a0 !important;
  /* border-radius: 100vh !important; */
  border: 3px solid #f6f7ed !important;
}

.display_scroll_bar::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
  background: #949394 !important;
}

/* Hide scrollbar on mobile screens */
@media (max-width:600px) {
  .display_scroll_bar {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
    ;
  }

  .review-container:before {
    display: none !important;
  }

  .display_scroll_bar::-webkit-scrollbar {
    width: 0 !important;
    display: none !important;
    /* For Chrome, Safari, and Opera */
    ;
  }
}

/* Ensure content can still scroll on mobile */
@media (max-width:600px) {
  .display_scroll_bar {
    -webkit-overflow-scrolling: touch;
    /* Enables smooth scrolling on iOS */
    overflow-y: auto;
  }
}

.css-1mhe1eg-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiTextField-root {
  background-color: #FFFFFF;
}

.react-multi-email>input {
  background-color: #FFFFFF !important;
}


/* .sidebar_popover {
  top: 0px !important;
  left: 0px !important;
  max-height: 100vh !important;
  max-width: 60% !important;
  width: 100%;
} */

.sidebar_popover>.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  top: 0px !important;
  left: 0px !important;
  max-height: 100vh !important;
  max-width: 60% !important;
  width: 100%;
}

.MuiOutlinedInput-root legend {
  width: 0px !important;
}

@media only screen and (max-width:600px) {
  .main-container {
    width: 100% !important;
  }

  .sidebar {
    width: 100% !important;
  }

  .m_sidebar {
    display: block !important;
  }

  .w_sidebar {
    display: none !important;
  }

  .m_statement_upload {
    left: 0% !important;
    top: 0% !important;
    position: relative !important;
    padding-top: 20% !important;
  }

  .m_login_left_box {
    display: none !important;
  }

  .sidebar_open_close_icon {
    display: block !important;
  }
}

.emailButtons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.emailButton-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 16px;
  color: #646f79;
  border: 2px solid #d5dce0;
  border-radius: 3px;
  margin-left: 12px;
}

.emailButton-text {
  flex-grow: 0;
  display: inline-block;
  color: #646f79;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
}

.emailButton-img {
  height: 24px;
  width: 24px;
  margin-right: 12px;
  flex-grow: 0;
  line-height: 48px;
}

.emailButton {
  display: block;
  text-decoration: none;
}

.emailButtons .emailButton {
  margin: 0 24px 0 0;
}

.emailButtons .emailButton:last-of-type {
  margin: 0;
}

.badge {
  min-width: 40;
  min-height: 40;
  padding: 10px;
  color: #000000;
  align-items: center;
  border-radius: 50%;
  transform: translate(50%, -50%);
  background: radial-gradient(circle closest-side, white calc(100% - 2px), #d5dce0 calc(100% - 1px) 99%, transparent 100%);
}

/* .mentions_input__suggestions__item__highlight {
  font-weight: 100;
} */


/* .sort-icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  margin-right: -5px;
  vertical-align: middle;
  border: 4px solid transparent;
}

.sort-icon.asc {
  border-bottom-color: #000;
}

.sort-icon.desc {
  border-top-color: #000;
} */


/* start css code for mention component */
textarea::placeholder {
  opacity: 0.50;
  font-size: 12px;
  color: #2F2F2E;
  font-weight: 400;
  line-height: 16px;
  font-style: normal;
  font-family: 'Noto Sans';
}

.mentions_input textarea:focus-visible {
  outline: 0;
}

.mentions_input textarea:focus {
  border-color: #2464eb !important;
  box-shadow: 0 0 0 1px #2464eb !important;
}

.mentions_input textarea:not(:focus):hover {
  border-color: #000000 !important;
}

.mentions_input_error textarea:focus-visible {
  outline: 0;
}

.mentions_input_error textarea:focus {
  border-color: #d32f2f !important;
  box-shadow: 0 0 0 1px #d32f2f !important;
}

.mentions_input_error textarea:not(:focus):hover {
  border-color: #d32f2f !important;
}

/* end css code for mention component */

.custom-card {
  background: #FFF;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04);
}

.custom-bullets {
  padding-left: 0px;
  list-style-type: none;
}

.custom-bullets li {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
}

.custom-bullets li svg {
  padding-right: 10px;
}

.edit_icon {
  visibility: visible;
}

.contactInfo_email:hover .edit_icon {
  visibility: visible;
}

.contactInfo_Mobile_number:hover .edit_icon {
  visibility: visible;
}

.status_badge {
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 0.5em 2em;
  border-radius: 10px;
  display: inline-block;
}

.status_badge.approve {
  background-color: #28a745;
  /* Green for Approved */
  ;
}

.status_badge.pending {
  background-color: #ffc107;
  /* Yellow for Pending */
  ;
}

.status_badge.expired {
  background-color: #dc3545;
  /* Red for Expired */
  ;
}

.responsive-iframe {
  zoom: 1;
}

/* Medium screens (md) */
@media (max-width:1200px) {
  .responsive-iframe {
    zoom: 0.9;
  }
}

/* Small screens (sm) */
@media (max-width:992px) {
  .responsive-iframe {
    zoom: 0.8;
  }
}

/* Extra small screens (xs) */
@media (max-width:768px) {
  .responsive-iframe {
    zoom: 0.7;
    height: 100vh;
    overflow: hidden !important;
  }
}

@page {
  size: A4;
}

.page {
  min-height: calc(100vh - 800px);
}

.powered-by {
  gap: 5px;
  bottom: 10px;
  display: flex;
  padding-left: 14px;
  position: absolute;
  align-items: center;
}

.powered-by p {
  margin: 0px;
}

.powered-by__logo {
  width: 25px;
  height: 25px;
  margin-top: -5px;
}

/* ########################## New AuditCompliant css  #########################################*/

.banner-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
}

.banner-card:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .08);
  transform: translateY(-2rem);
  cursor: pointer;
}

.banner-card:hover .anchorText {
  border-bottom: none;
}

.iconWrapper {
  object-fit: cover;
  background-color: #fafafa;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* for Mobile */
@media (max-width:600px) {
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (min-width:601px) and (max-width:960px) {
  .banner-card {
    min-height: 400px;
  }
}

@media (min-width:961px) {
  .banner-card {
    min-height: 420px;
  }
}

/*############### New Benefits Section CSS  ####################*/

.sections-container {
  width: 100%;
  position: relative;
}

.section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.content-wrapper {
  max-width: 28rem;
  width: 100%;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.section-text {
  font-size: 1.125rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

/* Section background colors - Left side */
.left-section-1,
.left-section-2,
.left-section-3,
.left-section-4 {
  background-color: #FFFFFF;
}

/* Section background colors - Right side */
.right-section-1,
.right-section-2,
.right-section-3,
.right-section-4 {
  background-color: #F9F9F9;
}

/* Mobile section styles */
@media (max-width:600px) {
  .section-title {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }

  .section-text {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .mobile-section {
    min-height: auto;
  }

  .mobile-content {
    text-align: left;
    padding: 0;
  }

  .mobile-image {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .mobile-image svg {
    max-width: 100%;
    height: auto;
  }
}

/* ########## New Reviews Section CSS ##############*/

.toggle-arrows-container {
  position: absolute;
  bottom: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 1.5rem;
  gap: 2rem;
}

.left-arrow {
  display: inline-flex;
  padding: 0;
  border: none;
  margin: 0;
  background: 0 0;
  cursor: pointer;
  line-height: 1;
}

.right-arrow {
  display: inline-flex;
  padding: 0;
  border: none;
  margin: 0;
  background: 0 0;
  cursor: pointer;
  line-height: 1;
  transform: rotate(180deg);
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  gap: 30px;
  overflow: visible;
}

.c-integrations-item {
  height: 340px;
  /* Set a fixed height for the card */
  padding: 2.5rem 2rem;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 8%);
  width: 391px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.c-integrations-item__inner {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.c-integrations-item__top {
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
}

.c-integrations-item__name {
  letter-spacing: .88px;
  color: var(--wp--preset--color--dark-100);
  font-size: var(--wp--preset--font-size--24);
  font-weight: 600;
  letter-spacing: .96px;
  line-height: 1.4;
  margin-left: 10px;
}

.c-integrations-item__info {
  margin-bottom: 1.5rem;
  color: var(--wp--preset--color--dark-100);
  font-size: var(--wp--preset--font-size--14);
  font-weight: 400;
  letter-spacing: 0.56px;
  line-height: 1.7;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.c-integrations-item__button {
  margin-top: auto;
}

.review-container:after {
  position: absolute;
  top: -44px;
  right: 100px;
  display: flex;
  overflow: hidden;
  width: 262px;
  height: 118px;
  flex-basis: 262px;
  flex-shrink: 0;
  margin: 0;
  background-image: url(https://landingi.com/wp-content/themes/landingi/dist/../img/blocks/cta/ornament-right.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  content: "";
  pointer-events: none;
}

.review-container:before {
  position: absolute;
  top: unset;
  bottom: 7%;
  left: -151px;
  display: flex;
  width: 207px;
  height: 135px;
  flex-shrink: 0;
  margin: 0;
  background-image: url(https://landingi.com/wp-content/themes/landingi/dist/../img/blocks/cta/ornament-left.svg);
  background-position: 0;
  background-repeat: no-repeat;
  content: "";
  pointer-events: none;
}

/* /////////////// New Pricing Section CSS */

.pricelist-switcher {
  display: grid;
  align-items: center;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: 1fr 3.375rem 1fr;
  padding-bottom: 4px;
}

.pricelist-switcher__text--left {
  color: var(--wp--preset--color--classicblue-100);
  justify-self: flex-end;
  text-align: right;
}

.pricelist-switcher__text {
  color: var(--wp--preset--color--dark-100);
  font-size: var(--wp--preset--font-size--14);
  font-weight: 600;
  letter-spacing: .56px;
  line-height: 1;
}

.pricelist-switcher__text--right {
  justify-self: flex-start;
  text-align: left;
}

.pricelist-switcher__text {
  color: var(--wp--preset--color--dark-100);
  font-size: var(--wp--preset--font-size--14);
  font-weight: 600;
  letter-spacing: .56px;
  line-height: 1;
}

.features-text {
  margin-bottom: 0.3rem;
  line-height: 1.4;
  letter-spacing: .64px;
  font-size: var(--wp--preset--font-size--16) !important;
  font-family: Inter, sans-serif;
}

.pricing-list-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

/* ///////// */
/* .b-pricelist-switcher__toggle {
  position: relative;
  display: inline-block;
  width: 3.375rem;
  height: 1.625rem;
  border-radius: 1.625rem;
  background: var(--wp--preset--color--dark-40);
  cursor: pointer;
}

.b-pricelist-switcher--on .b-pricelist-switcher__toggle:before {
  background: var(--wp--preset--color--white-100);
  transform: translateX(1.75rem);
}

.b-pricelist-switcher__toggle:before {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: var(--wp--preset--color--white-100);
  content: "";
  transition: transform .4s;
}

.b-pricelist-switcher--on .b-pricelist-switcher__toggle {
  background: var(--wp--preset--color--classicblue-100);
} */


/*############################ Media Query CSS ###########################################*/

@media (max-width:740px) {
  .banner-root {
    height: calc(100vh - 300px);
  }
}

.auth_bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: #2464eb;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/svg/AuthPagesBGImage.svg');
}
