.custom-container {
  box-shadow: none !important;
  background: #FAFAFA !important;
  height: calc(100vh - 70px) !important;
}

.box {
  padding: 16px !important;
  background: #FFFFFF !important;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.04) !important;
}

.box-mt-4{
  margin-top: 32px !important;
}

.box-first {
  margin-top: 0 !important;
}