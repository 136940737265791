.scrollbarHidden::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 600px) {
    .fontSize {
        font-size: 10px !important;
        font: optional !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .fontSize {
        font-size: 10px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .fontSize {
        font-size: 18px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .fontSize {
        font-size: 18px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .fontSize {
        font-size: 10px !important;
    }
}