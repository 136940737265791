.barcode-custom-card {
    padding: 16px;
    border-radius: 8px;
    background:#FFF ;
    border: 1px solid #e0e0e0;
    box-shadow:1px 2px 4px 0px rgba(0, 0, 0, 0.04);
}

.barcode-section {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
}
.barcode{
    margin-bottom: 20px;
}
.barcode>div>div>h3 {
    margin: 0;
    font-size: 12px !important;
}

.barcode>div>div>img {
    width: 100%;
    height: 100%;
    max-width: 200px;
    min-width: 120px;
    min-height: 70px;

}

.message-main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message-container {
    gap: 10px;
    display: flex;
    align-items: center;
}

.message {
    font-size: 12px;
    color: #D5952C;
    padding: 8px 10px;
    border-radius: 5px;
    background-color: #fff4e5bf;
}

.paper-size {
    font-size: 12px;
    color: #666666;
    padding: 8px 10px;
    border-radius: 5px;
    background-color: #e0e0e059;
}


@media (max-width:1000px) {
    .barcode>div>div>h3 {
        margin: 0;
        font-size: 10px !important;
    }

    .barcode>div>div>img {
        width: 100%;
        height: 100%;
        min-width: 120px;
        min-height: 60px;

    }
}


@media (max-width:800px) {
    .barcode>div>div>h3 {
        margin: 0;
        font-size: 14px !important;
    }

    .barcode>div>div>img {
        width: 100%;
        height: 100%;
        min-width: 140px;
        min-height: 70px;

    }

}