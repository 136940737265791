.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
   padding: 1px !important; 
}

::-webkit-validation-bubble-message { display: none; }


.css-1cb285q-MuiDateRangePickerInput-root {
  display: flex;
  align-items: center !important;
  margin-bottom: 10px;
}

.custom_table_css >table>thead>tr>th,
.custom_table_css >table>tbody>tr>td {
  padding: 4px !important;
  padding-top: 10px !important;
  padding-bottom: 16px !important;
}
.custom_table_css >table>tbody>tr>td>div>div>div>input {
  padding: 7.5px 4px !important;
}

.custom_table_css >table>tbody>tr>td>.tax_dropdown >div>div {
  padding-top: 0px !important;
  padding-left: 1px !important;
  padding-bottom: 0px !important;
}

.custom_table_css >table>tbody>tr>td>.item_dropdown >div>div {
  padding-top: 5px !important;
  padding-left: 1px !important;
  padding-bottom: 5px !important;
}
.custom_table_css >table>tbody>tr>td>.item_dropdown >div>div>input{
  padding: 1.5px 10px 1.5px 0px !important;
}
